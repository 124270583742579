<template>
  <v-container v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Quotation
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col md="10">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 75vh; position: relative"
        >
          <QuotationBarcode></QuotationBarcode>
          <div>
            <v-card
              flat
              class="custom-grey-border remove-border-radius mt-4"
              v-if="false"
            >
              <v-card-title class="headline grey lighten-4">
                <v-row>
                  <v-col md="6" class="py-0 my-auto">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                      style="margin: 3.5px 0px"
                    >
                      Quotation To Invoice
                    </h3>
                    <p
                      v-if="reLoginRequired()"
                      class="my-2 text-warning font-weight-600 font-size-18"
                    >
                      <v-icon class="mr-2" color="warning"
                        >mdi-alert-outline</v-icon
                      >
                      Please logout and login again to see the effect of Allow
                      Quotation Approvel.
                    </p>
                  </v-col>
                  <template v-if="getPermission('setting:update')">
                    <v-col md="6" class="text-right py-0">
                      <v-btn
                        depressed
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="updateInvoiceSetting"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Update</span>
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>
              <v-card-text
                class="p-6 font-size-16"
                :class="{
                  'pointer-events-none': !getPermission('setting:update'),
                }"
              >
                <v-row>
                  <v-col md="12" class="pb-4">
                    <table class="width-100 mt-0">
                      <tbody>
                        <tr>
                          <v-switch
                            v-model="setting.allow_quotation_approver"
                            class="mt-0"
                            inset
                            label="Approval process required"
                            color="green"
                          ></v-switch>
                        </tr>
                        <tr>
                          <v-switch
                            v-model="setting.is_quotation_invoice_allow"
                            class="mt-0"
                            inset
                            label="Is Invoice Needed"
                            color="green"
                            v-on:change="
                              setting.is_quotation_invoice_allow
                                ? (setting.quotation_recurring_invoice = false)
                                : (setting.quotation_recurring_invoice = false)
                            "
                          ></v-switch>
                        </tr>
                        <tr>
                          <v-switch
                            v-model="setting.quotation_recurring_invoice"
                            class="mt-0"
                            inset
                            label="Recurring Invoice allowed"
                            color="green"
                            :disabled="
                              !setting.is_quotation_invoice_allow ? true : false
                            "
                          ></v-switch>
                        </tr>

                        <!--  <tr>
                          <v-switch
                            v-model="setting.full_payment"
                            class="mt-0"
                            inset
                            label="100 %  Payment invoice "
                            color="green"
                            v-on:change="
                              setting.full_payment
                                ? (setting.payment_in_milestones = false)
                                : (setting.payment_in_milestones = true)
                            "
                          ></v-switch>
                        </tr>
                        <tr>
                          <v-switch
                            v-model="setting.payment_in_milestones"
                            class="mt-0"
                            inset
                            label="Payment in Milestones"
                            color="green"
                            v-on:change="
                              setting.payment_in_milestones
                                ? (setting.full_payment = false)
                                : (setting.full_payment = true)
                            "
                          ></v-switch>
                        </tr> -->
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="mt-4">
            <note module="quotation"></note>
          </div>
          <div>
            <v-card
              flat
              class="custom-grey-border remove-border-radius mt-4"
              v-if="false"
            >
              <v-card-title class="headline grey lighten-4">
                <v-row>
                  <v-col md="4" class="py-0 my-auto">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                      style="margin: 3.5px 0px"
                    >
                      Add/Edit Quotation validity
                    </h3>
                  </v-col>
                  <template v-if="getPermission('setting:update')">
                    <v-col md="8" class="text-right py-0">
                      <div class="d-flex justify-content-end">
                        <v-btn
                          depressed
                          small
                          class="white--text"
                          v-on:click="locationDialogOpen"
                          color="cyan"
                        >
                          <span class="font-size-16 font-weight-600"
                            >Create</span
                          >
                        </v-btn>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>
              <v-card-text
                class="p-6 font-size-16"
                :class="{
                  'pointer-events-none': !getPermission('setting:update'),
                }"
              >
                <v-row>
                  <v-col md="12" class="pb-4">
                    <v-simple-table
                      class="simple-table"
                      fixed-header
                      style="min-height: 200px"
                      :height="locationList?.length > 0 ? 'auto' : '100px'"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="simple-table-th" width="50">#</th>
                            <th class="simple-table-th" width="500">
                              Duration
                            </th>
                            <th class="simple-table-th">Type</th>
                            <th class="simple-table-th" width="70">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="locationList?.length > 0">
                          <tr
                            v-for="(location, index) in locationList"
                            :key="index"
                            class="alternate-listing-row"
                          >
                            <td width="50" class="simple-table-td">
                              {{ index + 1 }}
                            </td>
                            <td
                              width="100"
                              class="simple-table-td text-capitalize"
                            >
                              <template v-if="location?.value">
                                <v-badge
                                  color="red"
                                  class="mr-9 ml-2"
                                  :content="location?.value"
                                >
                                </v-badge>
                              </template>
                              <em v-else class="text-muted">
                                <v-badge
                                  color="red"
                                  class="mr-9 ml-2"
                                  content="0"
                                >
                                </v-badge>
                              </em>
                            </td>
                            <td class="simple-table-td">
                              <span class="font-weight-600"> Days </span>
                            </td>

                            <td class="simple-table-td">
                              <v-icon
                                color="cyan"
                                size="20"
                                v-on:click="updateLocationType(location)"
                              >
                                mdi-pencil</v-icon
                              >
                              <v-icon
                                color="red"
                                class="ml-2"
                                size="20"
                                v-on:click="deleteLocation(location)"
                              >
                                mdi-delete</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                        <tr v-else>
                          <td colspan="5" class="py-4">
                            <p
                              class="m-0 row-not-found text-center font-weight-500 font-size-16"
                            >
                              <img
                                :src="$assetURL('media/error/empty.png')"
                                class="row-not-found-image"
                              />
                              Uhh... There are no Validity at the moment.
                            </p>
                          </td>
                        </tr>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row
                  justify="end"
                  class="align-center pagination_btn listing-footer px-2"
                >
                  <p class="m-0 listing-footer-count">
                    Showing {{ (currentPage - 1) * itemsPerPage + 1 }} to
                    {{
                      Math.min(currentPage * itemsPerPage, locationList.length)
                    }}
                    of {{ locationList.length }} entries
                  </p>
                  <v-spacer></v-spacer>
                  <v-pagination
                    v-model="currentPage"
                    :length="pageCount"
                    total-visible="7"
                    color="cyan"
                    @input="handlePageChange"
                  ></v-pagination>
                </v-row>
              </v-card-text>
            </v-card>
            <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
              <template v-slot:title> Confirmation </template>
              <template v-slot:body>
                <v-form
                  ref="statusForm"
                  v-model.trim="formValid"
                  lazy-validation
                >
                  <v-container class="px-10" fluid>
                    <h3 class="font-weight-500 text-center">
                      Validity already exists in another transaction. Any
                      updates will be reflected in a new transaction.
                    </h3>
                  </v-container>
                </v-form>
              </template>
              <template v-slot:action>
                <v-btn
                  :disabled="confirm_loading"
                  v-on:click="confirm_dialog = false"
                  class="mx-2 custom-grey-border custom-bold-button"
                >
                  No! Close
                </v-btn>
                <v-btn
                  v-on:click="updateLocationSetting()"
                  :disabled="!formValid || confirm_loading"
                  :loading="confirm_loading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                >
                  Yes! Continue
                </v-btn>
              </template>
            </Dialog>
            <Dialog :commonDialog="locationDialog" :dialog-width="850">
              <template v-slot:title>
                <template v-if="locationPayload?.id > 0">Edit</template
                ><template v-else>Add</template> Validity
              </template>
              <template v-slot:body>
                <v-container fluid>
                  <v-form
                    ref="ticketCollecttionFormSkills"
                    v-model.trim="formValid"
                    lazy-validation
                    v-on:submit.stop.prevent="updateLocationSetting"
                  >
                    <v-row>
                      <v-col md="12">
                        <label class="my-auto fw-500 required mb-1"
                          >Validity (Days)
                        </label>
                        <v-text-field
                          type="number"
                          filled
                          placeholder="Duration"
                          solo
                          flat
                          color="cyan"
                          class="width-100"
                          dense
                          v-model.trim="locationPayload.validity"
                          v-mask="'####'"
                          :rules="[
                            validateRules.required(
                              locationPayload.validity,
                              'validity'
                            ),
                          ]"
                          :min="1"
                          oninput="if(this.value < 0) this.value = 0;"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </template>
              <template v-slot:action>
                <v-btn
                  depressed
                  :loading="pageLoading"
                  :disabled="pageLoading || !formValid"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="updateLocationSettingType(is_exist)"
                >
                  Save
                </v-btn>
                <v-btn
                  depressed
                  :disabled="pageLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  v-on:click="locationDialog = false"
                >
                  Close
                </v-btn>
              </template>
            </Dialog>

            <DeleteDialog :delete-dialog="deleteLocationDialog">
              <template v-slot:title>Delete Confirmation</template>
              <template v-slot:text>
                <h3 class="font-size-18 font-weight-600 delete-text">
                  Are you sure you want to delete this validity duration ?
                </h3>
              </template>
              <template v-slot:action>
                <v-btn
                  :disabled="deleteLocationLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  v-on:click="deleteLocationDialog = false"
                  >No! Cancel</v-btn
                >
                <v-btn
                  :disabled="deleteLocationLoading"
                  :loading="deleteLocationLoading"
                  color="red lighten-1 white--text"
                  v-on:click="deleteLocationConfirm()"
                  class="mx-2 custom-grey-border custom-bold-button"
                  >Yes! Delete</v-btn
                >
              </template>
            </DeleteDialog>
          </div>
          <v-card
            flat
            class="custom-setting-height custom-grey-border remove-border-radius mt-4"
          >
            <v-card-text class="headline sticky-header grey lighten-4 pa-0">
              <TagList internal module="quotation"></TagList>
            </v-card-text>
          </v-card>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Note from "@/view/pages/profile/Setting/Note";
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import { setCookie, getCookie } from "@/core/plugins/bt-cookie";
import QuotationBarcode from "@/view/pages/profile/Setting/Partial/Quotation-barcode";
import TagList from "@/view/pages/profile/Setting/Tags-List";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export default {
  name: "profile-invoice-setting",
  mixins: [ValidationMixin],

  data() {
    return {
      deleteLocationLoading: false,
      pageLoading: true,
      locationPayload: {
        validity: null,
        is_active: 1,
      },
      locationPageLoading: false, // or your actual loading state variable
      itemsPerPage: 10,
      currentPage: 1,
      is_exist: 0,
      confirm_dialog: false,
      confirm_loading: false,
      deleteLocationDialog: false,
      locationDialog: false,
      locationList: [],
      dataLoading: false,
      setting: {
        payment_in_milestones: false,
        quotation_recurring_invoice: true,
        is_quotation_invoice_allow: false,
        allow_quotation_approver: false,
        full_payment: true,
      },
    };
  },
  components: {
    QuotationBarcode,
    TagList,
    Note,
    Dialog,
    DeleteDialog,
  },
  methods: {
    /* validity start  */
    deleteLocation(data) {
      this.zone_type_id = data.id;
      this.is_exist = data.is_exist ? 1 : 0;
      this.deleteLocationDialog = true;
    },
    deleteLocationConfirm() {
      const _this = this;
      _this.deleteLocationLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `setting/validity/${this.zone_type_id}`,
        })
        .then(() => {
          _this.deleteLocationLoading = false;
          _this.deleteLocationDialog = false;
          _this.getQuotationValidity();
        })
        .catch((error) => {
          _this.logError(error);
          _this.deleteLocationLoading = false;
        });
    },

    updateLocationType(param) {
      const _this = this;
      _this.is_exist = param.is_exist ? 1 : 0;
      _this.locationDialog = true;

      setTimeout(function () {
        _this.locationPayload = {
          id: _this.lodash.toSafeInteger(param.id),
          validity: param?.value ? param.value : null,
          is_active: param.is_active ? param.is_active : 1,
        };
      }, 500);
    },
    updateLocationSettingType(type) {
      if (type == 0) {
        this.updateLocationSetting();
      } else {
        this.confirm_dialog = true;
      }
    },
    updateLocationSetting() {
      const _this = this;
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormSkills
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.pageLoading = false;
        }
        return false;
      }

      _this.pageLoading = true;
      _this.dataLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "setting/validity/quotation",
          data: _this.locationPayload,
        })
        .then(() => {
          _this.getQuotationValidity();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
          _this.pageLoading = false;
          _this.locationDialog = false;
        });
    },

    locationDialogOpen() {
      this.locationPayload = {
        name: null,
        is_active: 1,
      };
      this.locationDialog = true;
    },
    getQuotationValidity() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/validity/quotation" })
        .then(({ data }) => {
          _this.locationList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    /* validity end*/
    reLoginRequired() {
      return !!+getCookie("quotation_reload");
    },
    getInvoiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/invoice" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateInvoiceSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/invoice/quotation",
          data: _this.setting,
        })
        .then(() => {
          this.getInvoiceSetting();
          setCookie({ key: "quotation_reload", value: 1 });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    handlePageChange(page) {
      this.locationPageLoading = true;
      this.currentPage = page;
      setTimeout(() => {
        this.locationPageLoading = false;
      }, 1000);
    },
  },
  mounted() {
    this.getInvoiceSetting();
    this.getQuotationValidity();
  },
  computed: {
    pageCount() {
      return Math.ceil(this.locationList.length / this.itemsPerPage);
    },
    paginatedLocationList() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.locationList.slice(start, end);
    },
  },
};
</script>
