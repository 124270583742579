<template>
  <v-container
    class="tag-list-height pa-0"
    fluid
    v-if="getPermission('setting:view')"
  >
    <v-layout>
      <v-flex>
        <h3
          v-if="internal"
          class="font-weight-700 custom-headline color-custom-blue"
          style="margin: 3.5px 0px"
        >
          Tags
        </h3>
        <h3
          v-else
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Tags
        </h3>
      </v-flex>
      <div class="mr-2 d-flex" v-if="!internal">
        <label for="" class="font-weight-700 mr-2"> Filter By</label>
        <v-autocomplete
          hide-details
          v-model.trim="type_filter"
          :items="tagModules"
          dense
          flat
          filled
          style="max-width: 250px"
          placeholder="Module Type"
          item-color="cyan"
          color="cyan"
          solo
          @change="getTags()"
          item-value="value"
          item-text="text"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Category(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-action class="ma-0">
              <v-chip
                style="height: 16px; width: 16px; padding: 0"
                :color="item.color"
              >
              </v-chip>
            </v-list-item-action>
            <v-list-item-content class="py-0">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
              >
                <template v-if="item.value == 'all'">{{ item.text }} </template>
                <template v-else>{{ item.text }} </template>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-action class="mr-0">
              <v-chip
                style="height: 16px; width: 16px; padding: 0"
                :color="item.color"
              >
              </v-chip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <v-btn
          depressed
          small
          class="text-white mr-2"
          color="cyan"
          v-on:click="categoryDialogOpen('tag')"
          :disabled="dataLoading"
          :loading="dataLoading"
        >
          <span class="font-size-16 font-weight-600">Create</span>
        </v-btn>
      </div>
      <div class="" v-if="!internal">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </v-layout>
    <ListingTable :columnCount="6" :rowData="rowData">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="10">#</th>
            <th class="simple-table-th">Tag</th>
            <th class="simple-table-th">Module</th>
            <th class="simple-table-th">Count</th>
            <!--  <th class="simple-table-th">Action</th> -->
            <!--  <th class="simple-table-th">Status</th> -->
            <!--  <th class="simple-table-th">Created Time</th> -->
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData.length">
            <tr
              v-for="(row, index) in rowData"
              :key="index"
              class="alternate-listing-row"
            >
              <td class="simple-table-td pl-4">
                {{ index + 1 }}
              </td>
              <td class="simple-table-td text-capitalize pl-4">
                <v-chip label small :color="row?.color" text-color="white">{{
                  row?.text
                }}</v-chip>
              </td>
              <td class="simple-table-td text-capitalize font-weight-700 pl-4">
                <template v-if="row.type == 'asset'"> Own Asset </template>
                <template v-else-if="row.type == 'client_equipment'">
                  Customer Asset
                </template>
                <template v-else-if="row.type == 'goods'"> Product </template>
                <template v-else-if="row.type == 'customer'">
                  Company
                </template>
                <template v-else>
                  {{ row.type }}
                </template>
              </td>
              <td class="simple-table-td pl-4">
                <div class="invoice-listing-customer">
                  <v-badge
                    :color="getTypeColor(row)"
                    class="mr-9 ml-2"
                    :content="getTypeWiseCount(row)"
                  >
                  </v-badge>
                </div>
              </td>
              <!-- <td class="simple-table-td">
                <div class="invoice-listing-amount">
                </div>
              </td> -->
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no tags at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
      removePage
    ></ListingFooter>
    <Dialog :commonDialog="categoryDialog" :dialog-width="850">
      <template v-slot:title>
        Create Tags
        <!--   <template v-if="holidayData.id">Update </template>
          <template v-else>Create </template>Holiday -->
      </template>
      <template v-slot:body>
        <v-container fluid>
          <v-form
            ref="ticketCollecttionFormSkills"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateCategories"
          >
            <v-row>
              <v-col md="12" v-if="!internal">
                <label class="my-auto fw-500 required mb-1">Module Type</label>
                <v-autocomplete
                  hide-details
                  v-model.trim="categoryPayload.type"
                  :items="tagModulesCreate"
                  :rules="[
                    validateRules.required(categoryPayload.type, 'Module Type'),
                  ]"
                  dense
                  flat
                  filled
                  placeholder="Module Type"
                  item-color="cyan"
                  color="cyan"
                  solo
                  item-value="value"
                  item-text="text"
                >
                </v-autocomplete>
              </v-col>
              <v-col md="12">
                <label class="my-auto fw-500 required mb-1">Tag</label>
                <TagAutoComplete v-model="categoryPayload.tags" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :loading="dataLoading"
          :disabled="dataLoading || !formValid"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="updateAllSetting(is_exist)"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="categoryDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <DeleteDialog :delete-dialog="deleteCategoryDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-18 font-weight-600 delete-text">
          Are you sure you want to delete this
          <span class="text-capitalize">{{ related_type }}</span
          >?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="deleteJobLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteCategoryDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="deleteJobLoading"
          :loading="deleteJobLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteJobTypeConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid>
            <h3 class="font-weight-500 text-center">
              <span class="text-capitalize">{{ related_type }}</span> already
              exists in another transaction. Any updates will be reflected in a
              new transaction.
            </h3>
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirm_loading"
          v-on:click="confirm_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="updateCategories(related_type)"
          :disabled="!formValid || confirm_loading"
          :loading="confirm_loading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { POST, DELETE, QUERY } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import { PaginationEventBus } from "@/core/lib/pagination.lib";

/*   import Color from "@/view/pages/engineer/detail/Color"; */

export default {
  mixins: [ValidationMixin, CommonMixin],
  name: "profile-service-setting",
  data() {
    return {
      makeBlank: false,
      type: null,
      rowData: [],
      formValid: true,
      categoryDialog: false,
      deleteJobLoading: false,
      related_id: null,
      is_exist: 0,
      related_type: null,
      type_filter: null,
      tagModules: [
        { value: "all", text: "All", color: "cyan" },
        { value: "customer", text: "Company", color: "#950c0c" },
        { value: "contact", text: "Contact", color: "purple" },
        { value: "costing", text: "Costing", color: "#2196F3" },
        { value: "quotation", text: "Quotation", color: "red" },
        { value: "order", text: "Order", color: "green" },
        /*         { value: "enquiry", text: "Enquiry", color: "purple" },
        { value: "visit", text: "Visit", color: "green" },
        { value: "parts", text: "Part", color: "#018ecd" },
        { value: "goods", text: "Product", color: "black" },
        { value: "service", text: "Service", color: "#0dd3d3" },
        { value: "tool", text: "Tool", color: "#57c065" },
        { value: "asset", text: "Own Asset", color: "#f78b84" },
        { value: "client_equipment", text: "Customer Asset", color: "#48b7b4" }, */
      ],
      tagModulesCreate: [
        { value: "customer", text: "Company", color: "#950c0c" },
        { value: "contact", text: "Contact", color: "purple" },
        /*    { value: "supplier", text: "Supplier", color: "#2196F3" }, */
        { value: "quotation", text: "Quotation", color: "red" },
        { value: "order", text: "Order", color: "green" },
        /* { value: "enquiry", text: "Enquiry", color: "purple" },
        { value: "visit", text: "Visit", color: "green" },
        { value: "parts", text: "Part", color: "#018ecd" },
        { value: "goods", text: "Product", color: "black" },
        { value: "service", text: "Service", color: "#0dd3d3" },
        { value: "tool", text: "Tool", color: "#57c065" },
        { value: "asset", text: "Own Asset", color: "#f78b84" },
        { value: "client_equipment", text: "Customer Asset", color: "#48b7b4" }, */
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      categoryPayload: {
        id: null,
        tags: [],
        type: null,
      },
      deleteCategoryDialog: false,
      confirm_dialog: false,
      confirm_loading: false,
      customerCategory: [],
      serviceCertificate: [],
      serviceLicense: [],
      show_skills: true,
      show_licence: true,
      dataLoading: false,
      pageLoading: false,
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
    };
  },
  props: {
    internal: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: null,
    },
  },
  methods: {
    getTypeWiseCount(row) {
      let return1 = "0";

      if (row.type == "quotation") {
        return1 = row.quotation_count;
      }
      if (row.type == "customer") {
        return1 = row.customer_count;
      }
      if (row.type == "contact") {
        return1 = row.contact_count;
      }
      if (row.type == "costing") {
        return1 = row.costing_count;
      }
      if (row.type == "order") {
        return1 = row.order_count;
      }
      if (row.type == "visit") {
        return1 = row.visit_count;
      }
      if (row.type == "parts") {
        return1 = row.parts_count;
      }
      if (row.type == "goods") {
        return1 = row.goods_count;
      }
      if (row.type == "service") {
        return1 = row.service_count;
      }
      if (row.type == "tool") {
        return1 = row.tool_equipment_count;
      }
      if (row.type == "asset") {
        return1 = row.asset_equipment_count;
      }

      if (row.type == "client_equipment") {
        return1 = row.customer_equipment_count;
      }
      if (return1 == 0) {
        return1 = "0";
      }
      return return1;
    },
    getTypeColor(row) {
      if (row.type == "quotation") {
        return "red";
      }
      if (row.type == "customer") {
        return "#950c0c";
      }
      if (row.type == "contact") {
        return "purple";
      }
      if (row.type == "costing") {
        return "#2196F3";
      }
      if (row.type == "order") {
        return "green";
      }
      if (row.type == "visit") {
        return "green";
      }
      if (row.type == "parts") {
        return "#018ecd";
      }
      if (row.type == "goods") {
        return "black";
      }
      if (row.type == "service") {
        return "#0dd3d3";
      }
      if (row.type == "tool") {
        return "#57c065";
      }
      if (row.type == "asset") {
        return "#f78b84";
      }
      if (row.type == "client_equipment") {
        return "#48b7b4";
      }
      return "61bfc1";
    },
    getTags() {
      const data = {
        current_page: this.currentPage,
        status: this.status,
        type: this.type_filter,
      };

      this.dataLoading = true;
      this.$store
        .dispatch(QUERY, { url: "tags-list", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.rowData = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    categoryDialogOpen(type) {
      if (type == "tag") {
        this.related_type = "tag";
      } /* else if (type == "certificate") {
          this.related_type = "Certificate";
        } else if (type == "license") {
          this.related_type = "License";
        } */

      this.categoryPayload = {
        id: null,
        tags: [],
        type: null,
      };

      this.categoryDialog = true;
    },
    updateCategoriesData(param, type) {
      if (type == "tag") {
        this.related_type = "tag";
      } /* else if (type == "certificate") {
          this.related_type = "Certificate";
        } else if (type == "license") {
          this.related_type = "License";
        } */
      const _this = this;
      _this.categoryDialog = true;
      _this.is_exist = param.is_exist ? 1 : 0;
      setTimeout(function () {
        _this.categoryPayload = {
          id: _this.lodash.toSafeInteger(param.id),
          color: param.color ? param.color : null,
          is_active: param.is_active ? param.is_active : 1,
          text: param.text ? param.text : null,
        };
      }, 500);
      this.getTags();
    },
    deleteSkills(data, type) {
      this.related_id = data.id;
      this.is_exist = data.is_exist ? 1 : 0;
      this.related_type = type;
      this.deleteCategoryDialog = true;
    },
    deleteJobTypeConfirm() {
      const _this = this;
      _this.deleteJobLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `categories/delete/${this.related_id}`,
          data: {
            is_exist: this.is_exist,
          },
        })
        .then(() => {
          _this.deleteJobLoading = false;
          this.deleteCategoryDialog = false;
          this.getTags();
        })
        .catch((error) => {
          _this.logError(error);
          _this.deleteJobLoading = false;
        });
    },
    updateAllSetting(type) {
      if (type == 0) {
        this.updateCategories(this.related_type);
      } else {
        this.confirm_dialog = true;
      }
    },
    updateCategories() {
      const _this = this;
      this.$refs.ticketCollecttionFormSkills.validate();
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormSkills
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.dataLoading = false;
        }
        return false;
      }
      _this.dataLoading = true;
      if (this.internal) {
        this.categoryPayload.type = this.module;
      }
      _this.$store
        .dispatch(POST, {
          url: "tags",
          data: _this.categoryPayload,
        })
        .then(() => {
          this.getTags();
          this.categoryDialog = false;
          this.confirm_dialog = false;

          this.categoryPayload.tags = [];
          this.lodash.cloneDeep(this.categoryPayload.tags);
          this.makeBlank = true;
        })
        .catch((error) => {
          _this.logError(error);
          _this.dataLoading = false;
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
  },
  beforeDestroy() {
    PaginationEventBus.$off("update:pagination");
  },
  mounted() {
    if (this.internal) {
      this.type_filter = this.module;
    }
    PaginationEventBus.$on("update:pagination", (param) => {
      this.currentPage = param;
      this.getTags();
    });
    this.getTags();
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
  components: {
    Dialog,
    DeleteDialog,
    ListingTable,
    ListingFooter,
    TagAutoComplete,
  },
};
</script>
